import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { IssueTypesItemFormComponent } from './issue-types-item-form/issue-types-item-form.component';
import { IssueTypesItemComponent } from './issue-types-item/issue-types-item.component';
import { IssueTypesListComponent } from './issue-types-list/issue-types-list.component';
import { IssueTypesRoutingModule } from './issue-types-routing.module';

@NgModule({
  declarations: [IssueTypesListComponent, IssueTypesItemComponent, IssueTypesItemFormComponent],
  imports: [CommonModule, IssueTypesRoutingModule, SharedModule],
})
export class IssueTypesModule {}
