<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        label="Message"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.CLOSE_ISSUES"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>

  <div class="flex flex-row-reverse gap-2">
    <!-- <app-button *ngIf="fieldViewMode != 'view' && showSaveAndClose" [action]="submitButtonAction"></app-button> -->
    @if (true) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
  </div>
</form>
