import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { IssuesItemFormComponent } from './issues-item-form/issues-item-form.component';
import { IssuesItemComponent } from './issues-item/issues-item.component';
import { IssuesListComponent } from './issues-list/issues-list.component';
import { IssuesRoutingModule } from './issues-routing.module';
import { IssuesTreeListComponent } from './issues-tree-list/issues-tree-list.component';
import { IssueChangeStatusFormComponent } from './issue-change-status-form/issue-change-status-form.component';
import { IssuesAuditItemFormComponent } from './issues-audit-item-form/issues-audit-item-form.component';

@NgModule({
  declarations: [
    IssuesListComponent,
    IssuesItemComponent,
    IssuesItemFormComponent,
    IssuesTreeListComponent,
    IssueChangeStatusFormComponent,
    IssuesAuditItemFormComponent,
  ],
  imports: [CommonModule, IssuesRoutingModule, SharedModule],
})
export class IssuesModule {}
